import { Grid, Flex, Box, Image, Text, Link as A } from "theme-ui";
import Vertical from "../../../../components/vertical";
import Editor from "../../../../components/editor";
import * as React from 'react';
export default {
  Grid,
  Flex,
  Box,
  Image,
  Text,
  A,
  Vertical,
  Editor,
  React
};